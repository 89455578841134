import isArray from 'lodash/isArray';

const report_filter = (entities, filter = {}) => {
  let filteredData = entities;
  let tipsters = [];
  if (filter.tipsters && !isArray(filter.tipsters)) {
    tipsters = [filter.tipsters];
  } else if (filter.tipsters && isArray(filter.tipsters)) {
    tipsters = [...filter.tipsters];
  }
  tipsters = tipsters.map(tipster => Number.parseInt(tipster, 10));
  if (tipsters.length > 0) {
    filteredData = filteredData.filter(entity =>
      tipsters.includes(entity.tipster.id),
    );
  }
  return filteredData;
};

const report_order = (entities, ordering) => {
  if (ordering) {
    // parse orderings
    const sortOrder = ordering.charAt(0) === '-' ? '-' : '';
    const orderBy = ordering.slice(sortOrder.length);
    // sort
    if (orderBy) {
      return entities.sort((a, b) => {
        let aOrder = '';
        let bOrder = '';
        if (orderBy === 'tipster') {
          aOrder = a.tipster.name || '';
          bOrder = b.tipster.name || '';
        } else {
          aOrder = a.stats[orderBy] ? a.stats[orderBy].value : -10000000;
          bOrder = b.stats[orderBy] ? b.stats[orderBy].value : -10000000;
        }

        if (sortOrder === '-') {
          if (aOrder > bOrder) {
            return -1;
          }
          if (aOrder < bOrder) {
            return 1;
          }
        } else {
          if (aOrder > bOrder) {
            return 1;
          }
          if (aOrder < bOrder) {
            return -1;
          }
        }
        return 0;
      });
    }
  }
  return entities;
};

export const report_calculate = (entities, query = {}) => {
  const filteredData = report_filter(entities, query);
  return report_order(filteredData, query.ordering);
};
