import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, intlShape } from 'react-intl';
import { Alert, Col, Row } from 'reactstrap';

import Card from 'components/Card';
import auth from 'containers/Auth/auth';

import Filter from './Filter';
import List from './List';
import UserDetailedReport from './UserDetailedReport';
import UserDetailsModal from './UserDetailsModal';

const Layout = (props, context) => {
  const user = auth.getUser();
  const isEditor =
    (user.groups.trais.includes('editor') ||
      user.groups.trais.includes('contributor')) &&
    !user.groups.trais.includes('admin');

  return (
    <Fragment>
      <Helmet
        title={context.intl.formatMessage({
          id: 'Reports > Reporting',
        })}
      />
      <div className="animated fadeIn">
        <Card
          title={{
            text: 'Reports > Reporting',
            withBorder: true,
            icon: 'icon-graph',
          }}
        >
          <Row className="pt-2">
            <Col>
              <div className="float-right form-group">
                <Filter {...props} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {isEditor ? (
                <>
                  {!props.entities?.[0]?.details?.length ? (
                    <Alert color="danger">
                      <FormattedMessage id="No content found" />
                    </Alert>
                  ) : (
                    <UserDetailedReport userDetails={props.entities[0]} />
                  )}
                </>
              ) : (
                <List {...props} />
              )}
            </Col>
          </Row>
        </Card>
      </div>

      <UserDetailsModal {...props} />
    </Fragment>
  );
};

Layout.contextTypes = {
  intl: intlShape,
};

export default Layout;
