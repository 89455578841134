import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createElement, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectLocation } from 'containers/App/selectors';

import { getNav } from './_nav';
import { getNavExternal } from './_nav_external';
import SidebarFooter from './SidebarFooter';
import SidebarForm from './SidebarForm';
import SidebarHeader from './SidebarHeader';
import SidebarMinimizer from './SidebarMinimizer';

// simple wrapper for nav-title item
const wrapper = item =>
  item.wrapper && item.wrapper.element
    ? createElement(item.wrapper.element, item.wrapper.attributes, item.name)
    : item.name;

const hideMobile = () => {
  if (document.body.classList.contains('sidebar-mobile-show')) {
    document.body.classList.toggle('sidebar-mobile-show');
  }
};

const handleNavDropdownClick = e => {
  e.preventDefault();
  e.target.parentElement.classList.toggle('open');
};

const getNavdropdownClasses = (location, routeName) =>
  location.pathname.includes(routeName)
    ? 'nav-item nav-dropdown open'
    : 'nav-item nav-dropdown';

const isExternal = url => {
  const link = url ? url.slice(0, 4) : '';
  return link === 'http';
};

const resetLocalStorage = () => {
  try {
    localStorage.setItem('navYScroll', 0);
    localStorage.setItem('windowHeight', window.innerHeight);
  } catch {
    // Do nothing
  }
};

const getLocalStorage = () => {
  try {
    return [
      localStorage.getItem('navYScroll'),
      localStorage.getItem('windowHeight'),
    ];
  } catch {
    return [];
  }
};

function Sidebar({ location }) {
  const navRef = useRef(null);

  useEffect(() => {
    const [savedNavScroll, savedWindowHeight] = getLocalStorage();
    const nav = navRef.current;

    if (
      savedNavScroll == null ||
      savedWindowHeight == null ||
      window.innerHeight !== savedWindowHeight
    ) {
      resetLocalStorage();
    }

    const handleNavScroll = () => {
      localStorage.setItem('navYScroll', nav.scrollTop);
    };

    nav.addEventListener('scroll', handleNavScroll);

    nav.scrollTop = savedNavScroll;

    return () => {
      nav.removeEventListener('scroll', handleNavScroll);
    };
  }, []);

  useEffect(() => {
    const nav = navRef.current;

    const handleWindowResize = () => {
      resetLocalStorage();
      nav.scrollTop = 0;
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // badge addon to NavItem
  const renderBadge = badge => {
    if (badge) {
      const classes = classNames(badge.class);
      return (
        <Badge className={classes} color={badge.variant}>
          {badge.text}
        </Badge>
      );
    }
  };

  // nav list section title
  const renderTitle = (title, key) => {
    const classes = classNames('nav-title', title.class);
    return (
      <li key={key} className={classes}>
        {wrapper(title)}{' '}
      </li>
    );
  };

  // nav list divider
  const renderDivider = (divider, key) => {
    const classes = classNames('divider', divider.class);
    return <li key={key} className={classes} />;
  };

  // nav label with nav link
  const navLabel = (item, key) => {
    const classes = {
      item: classNames('hidden-cn', item.class),
      link: classNames('nav-label', item.class ?? false),
      icon: classNames(
        item.icon ?? 'fa fa-circle',
        item.label.variant ? `text-${item.label.variant}` : false,
        item.label.class ?? false,
      ),
    };
    return navLink(item, key, classes);
  };

  // nav item with nav link
  const navItem = (item, key) => {
    const classes = {
      item: classNames(item.class),
      link: classNames(
        'nav-link',
        item.variant ? `nav-link-${item.variant}` : '',
      ),
      icon: classNames(item.icon),
    };
    return navLink(item, key, classes);
  };

  // nav link
  const navLink = (item, key, classes) => {
    const url = item.url ?? '';

    return (
      <NavItem key={key} className={classes.item}>
        {isExternal(url) ? (
          <RsNavLink href={url} className={classes.link} active>
            <i className={classes.icon} />
            {item.name}
            {renderBadge(item.badge)}
          </RsNavLink>
        ) : (
          <NavLink
            to={url}
            className={classes.link}
            activeClassName="active"
            onClick={hideMobile}
          >
            <i className={classes.icon} />
            {item.name}
            {renderBadge(item.badge)}
          </NavLink>
        )}
      </NavItem>
    );
  };

  // nav dropdown
  const navDropdown = (item, key) => (
    <li key={key} className={getNavdropdownClasses(location, item.url)}>
      <a
        className="nav-link nav-dropdown-toggle"
        href="/"
        onClick={handleNavDropdownClick}
      >
        <i className={item.icon} />
        {item.name}
      </a>
      <ul className="nav-dropdown-items">{navList(item.children)}</ul>
    </li>
  );

  // nav type
  const navType = (item, idx) =>
    item.title
      ? renderTitle(item, idx)
      : item.divider
        ? renderDivider(item, idx)
        : item.label
          ? navLabel(item, idx)
          : item.children
            ? navDropdown(item, idx)
            : navItem(item, idx);

  // nav list
  const navList = items => items.map((item, index) => navType(item, index));

  // sidebar-nav root
  return (
    <div className="sidebar">
      <SidebarHeader />
      <SidebarForm />
      <nav className="sidebar-nav" ref={navRef}>
        <Nav>
          {navList(getNav())}
          {renderDivider({})}
          {navList(getNavExternal())}
        </Nav>
      </nav>
      <SidebarFooter />
      <SidebarMinimizer />
    </div>
  );
}
Sidebar.propTypes = {
  location: PropTypes.object.isRequired,
};

export default compose(
  connect(
    createStructuredSelector({
      location: makeSelectLocation(),
    }),
  ),
)(Sidebar);
