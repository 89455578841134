import PropTypes from 'prop-types';

import Bugsnag from 'utils/bugsnag';

// TODO const FallbackComponent = ({ error, info }) => (
const FallbackComponent = () => (
  <div className="container">
    <div className="jumbotron mb-4">
      <h1 className="display-5">
        <span role="img" aria-label="Varning">
          ⚠️
        </span>{' '}
        Ett oväntat fel inträffade
      </h1>
      <p className="lead">
        Testa att ladda om sidan. Om det inte hjälper, rapportera problemet i{' '}
        <a
          target="_blank"
          rel="noopener"
          href="https://trmediaab.slack.com/archives/CDE4G933N"
        >
          #trmedia-webbsupport
        </a>{' '}
        på Slack. Ange gärna nedanstånde information för snabbare felsökning.
        <br />/ TR DEV
      </p>
      <p>
        Webbläsare: {navigator.userAgent}
        <br />
        TR Content: v.{process.env.REACT_APP_VERSION}
      </p>
      <hr className="my-4" />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => window.location.reload()}
      >
        Ladda om
      </button>
    </div>
  </div>
);

const ErrorBoundary = ({ children }) => {
  const BugsnagErrorBoundary = Bugsnag.getPlugin('react');
  return (
    <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </BugsnagErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
