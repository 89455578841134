import { endOfMonth, format, startOfMonth } from 'date-fns';
import { FormattedNumber } from 'react-intl';

import { LOCALE_DATE_FORMAT } from 'components/DayPicker/utils';
import { apiUrl } from 'utils/request';

const options = {
  endpoints: {
    list: (site, query) => ({
      url: apiUrl(site, '/content/race_report/compensation/', {
        start_date: query.start_date,
        end_date: query.end_date,
        published_by: query.userId,
      }),
    }),
  },
  query: {
    start_date: format(startOfMonth(new Date()), LOCALE_DATE_FORMAT),
    end_date: format(endOfMonth(new Date()), LOCALE_DATE_FORMAT),
  },
  pushQuery: true,
  entityMapList: [
    {
      field: 'published_by_name',
      label: 'Namn',
      sortable: true,
      format: value => value,
    },
    {
      field: 'details',
      label: 'Marked races',
      sortable: true,
      format: value => value.length,
    },
    {
      field: 'total_compensation',
      label: 'Compensation (SEK)',
      sortable: true,
      format: value => (
        <>
          <FormattedNumber value={value / 100} minimumFractionDigits={2} />
        </>
      ),
    },
  ],
};
export default options;
