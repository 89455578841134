import { SET_SITE, TYPES_FETCH, TYPES_FETCH_SUCCESS } from './constants';

export function fetchTypes() {
  return {
    type: TYPES_FETCH,
  };
}

export function fetchTypesSuccess(site, data) {
  return {
    type: TYPES_FETCH_SUCCESS,
    site,
    types: data,
  };
}

export function setSite(site) {
  return {
    type: SET_SITE,
    site,
  };
}
