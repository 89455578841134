const Item = ({ entity, entityMapList }) => (
  <tr>
    <td
      className="border-right bg-light"
      style={{
        position: 'sticky',
        left: 0,
        zIndex: 1,
        whiteSpace: 'nowrap',
      }}
    >
      {entity.tipster.name}
    </td>
    {entityMapList.reduce((columns, map) => {
      columns.push(
        ...map.types.map(({ key }, index) => {
          let label = entity.stats[key] ? entity.stats[key].value : null;
          const goal = map.types.find(type => type.key === key)?.goal;
          const color = goal ? (label >= goal ? 'green' : 'red') : null;

          if (
            label !== null &&
            key !== 'winners_rank_average' &&
            key !== 'winners_rank_average_atleast_10_gamepercent'
          ) {
            label += '%';
          }

          return (
            <td
              title={
                entity.stats[key]
                  ? `Antal tips: ${entity.stats[key].num_of_systems}`
                  : ''
              }
              className={`text-right ${index > 0 ? '' : 'border-left'}`}
              key={`stats-${entity.tipster.id}-${key}`}
              style={{ whiteSpace: 'nowrap', color }}
            >
              {label}
            </td>
          );
        }),
      );
      return columns;
    }, [])}
  </tr>
);

export default Item;
