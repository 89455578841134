/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import { AUTH_SUCCESS } from './constants';

// The initial state of the App
const initialState = fromJS({
  user: null,
  userUpdated: false,
});

function Reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_SUCCESS:
      return state.set('user', fromJS(action.user)).set('userUpdated', true);
    default:
      return state;
  }
}

export default Reducer;
