import PropTypes from 'prop-types';
import { parseUrl, stringify } from 'query-string';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Spinner from 'components/Spinner';
import { makeSelectLocation } from 'containers/App/selectors';
import auth from 'containers/Auth/auth';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  changeQuery,
  fetchEntities,
  setDetailedUser,
  setProps,
} from './actions';
import Layout from './Layout';
import options from './options';
import reducer from './reducer';
import saga from './saga';
import {
  makeSelectFinancialResultDetailedUser,
  makeSelectFinancialResultEntities,
  makeSelectFinancialResultEntitiesError,
  makeSelectFinancialResultEntitiesLoading,
  makeSelectFinancialResultEntitiesQuery,
} from './selectors';

class ReportSystemResults extends PureComponent {
  componentDidMount() {
    const { query } = parseUrl(this.props.location.search);
    this.props.onChangeQuery(query, true);
  }

  componentWillUnmount() {
    // reset state
    this.props.setProps();
  }

  render() {
    const query = options.pushQuery
      ? `?${stringify(
          Object.entries(
            this.props.query.toJS ? this.props.query.toJS() : this.props.query,
          ).reduce((acc, [key, value]) => {
            if (value !== '' && value !== null) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        )}`
      : {};
    return (
      <Spinner {...this.props} component={Layout}>
        {options.pushQuery && window.location.search !== query && (
          <Redirect
            push
            to={{
              search: query,
            }}
          />
        )}
      </Spinner>
    );
  }
}

ReportSystemResults.propTypes = {
  pushQuery: PropTypes.bool,
  location: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  query: PropTypes.object,
  setProps: PropTypes.func,
  entities: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  detailedUser: PropTypes.string,
  onChangeQuery: PropTypes.func.isRequired,
  setDetailedUser: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectFinancialResultEntitiesLoading(),
  error: makeSelectFinancialResultEntitiesError(),
  entities: makeSelectFinancialResultEntities(),
  query: makeSelectFinancialResultEntitiesQuery(),
  detailedUser: makeSelectFinancialResultDetailedUser(),
  location: makeSelectLocation(),
});

const mapDispatchToProps = dispatch => ({
  setProps: newProps => {
    dispatch(setProps(newProps));
  },
  onChangeQuery: (evt, triggerFetch, syncServer = true) => {
    const user = auth.getUser();
    const isEditor =
      (user.groups.trais.includes('editor') ||
        user.groups.trais.includes('contributor')) &&
      !user.groups.trais.includes('admin');

    const queryChange = evt;

    dispatch(changeQuery(queryChange));

    if (triggerFetch) {
      dispatch(fetchEntities(syncServer, isEditor ? user.id : undefined));
    }
  },
  setDetailedUser: user => {
    dispatch(setDetailedUser(user));
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'reportFinancials', reducer });
const withSaga = injectSaga({ key: 'reportFinancials', saga });

export default compose(withReducer, withSaga, withConnect)(ReportSystemResults);
