import { Component } from 'react';
import {
  Card as ReactstrapCard,
  CardBody as ReactstrapCardBody,
  CardFooter as ReactstrapCardFooter,
  Collapse,
} from 'reactstrap';

import CardHeader from './CardHeader';
import CardTitle from './CardTitle';

export default class Card extends Component {
  constructor(props) {
    super(props);
    const header = this.props.header || {};
    const actions = header.actions || [];
    const collapse = !actions.includes('collapse false');

    this.state = { collapse };
  }

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  render() {
    const { header, title, footer, body, children, ...rest } = this.props;
    if (
      header &&
      header.actions &&
      (header.actions.includes('collapse') ||
        header.actions.includes('collapse false'))
    ) {
      const otherActions = header.actions.filter(
        action => !['collapse', 'collapse false'].includes(action),
      );
      return (
        <ReactstrapCard {...rest}>
          {header && (
            <CardHeader
              {...header}
              actions={otherActions}
              collapse={{ toggle: this.toggle, state: this.state.collapse }}
            />
          )}
          <Collapse isOpen={this.state.collapse}>
            <ReactstrapCardBody {...body}>
              {title && <CardTitle {...title} />}
              {children}
            </ReactstrapCardBody>
          </Collapse>
          {footer && <ReactstrapCardFooter {...footer} />}
        </ReactstrapCard>
      );
    }
    return (
      <ReactstrapCard {...rest}>
        {header && <CardHeader {...header} />}
        <ReactstrapCardBody {...body}>
          {title && <CardTitle {...title} />}
          {children}
        </ReactstrapCardBody>
        {footer && <ReactstrapCardFooter {...footer} />}
      </ReactstrapCard>
    );
  }
}
