import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

import Aside from 'components/Aside/index';
import Footer from 'components/Footer/index';
import Header from 'components/Header/index';
import Sidebar from 'components/Sidebar/index';
import { SITES } from 'utils/constants';
import withSite from 'utils/withSite';

class DefaultLayout extends PureComponent {
  componentDidMount() {
    document.body.className =
      'app header-fixed sidebar-fixed aside-menu-fixed aside-menu-hidden';
  }

  render() {
    let siteTitle;
    switch (this.props.site) {
      case 'external_atg':
        siteTitle = 'ATG';
        break;
      case 'external_atg_international':
        siteTitle = 'ATG Internationellt';
        break;
      case 'andelsspel':
        siteTitle = 'Andelsspel';
        break;
      case 'galopptips':
        siteTitle = 'Galopptips';
        break;
      case 'joker_sport':
        siteTitle = 'Joker Sport';
        break;
      case 'jokersystemet':
        siteTitle = 'Jokersystemet';
        break;
      case 'jokersystemet_no':
        siteTitle = 'Jokersystemet NO';
        break;
      case 'overodds':
        siteTitle = 'Överodds';
        break;
      case 'spelvarde':
        siteTitle = 'Spelvarde';
        break;
      case 'travronden':
        siteTitle = 'Travronden';
        break;
      case 'travronden_spel':
        siteTitle = 'Travronden Spel';
        break;
      case 'trais':
        siteTitle = 'Trais';
        break;
      case 'trhub':
        siteTitle = 'TR Hub';
        break;
      case 'trmedia':
        siteTitle = 'TR Media';
        break;
      case 'external_tr_spel_b2b':
        siteTitle = 'TR Spel B2B';
        break;
      default:
        siteTitle = '';
    }
    return (
      <div className="app">
        <Helmet
          titleTemplate={`%s - ${siteTitle} - TR Content`}
          defaultTitle="TR Content"
        >
          <meta name="description" content="TR Media application" />
        </Helmet>
        <Header />
        <div className="app-body">
          <Sidebar />
          <main className="main">
            <Container fluid className="mt-3">
              {this.props.children}
            </Container>
          </main>
          <Aside />
        </div>
        <Footer />
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  site: PropTypes.oneOf(SITES),
};

export default withSite(DefaultLayout);
