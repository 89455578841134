import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectSite } from 'containers/App/selectors';
import { refreshAuthSaga } from 'containers/Auth/saga';
import request from 'utils/request';

import { fetchTypesSuccess } from './actions';
import { TYPES_FETCH } from './constants';
import options from './options';

export function* sagaFetchTypes() {
  const site = yield select(makeSelectSite());
  const endpoint = options.endpoints.types(site);

  try {
    const response = yield call(request, site, endpoint.url, endpoint.options);
    yield put(fetchTypesSuccess(site, response));
  } catch (error) {
    console.error(error);
  }
}

export default function* globalSaga() {
  yield all([takeLatest(TYPES_FETCH, sagaFetchTypes)]);
  yield call(refreshAuthSaga);
}
