import { apiUrl } from 'utils/request';

const options = {
  endpoints: {
    list: site => ({
      url: apiUrl(site, `reports/automated_text_meets/`, {}),
    }),
  },
};

export default options;
