import { intlShape } from 'react-intl';
import { Button as ReactstrapButton } from 'reactstrap';

import Link from 'components/Link';

const Button = (
  { text, icon, to, color = 'secondary', translate = true, ...props },
  context,
) => {
  let className = props.className || '';
  className += ` btn btn-${color} `;

  if (to) {
    return (
      <Link
        to={to}
        {...props}
        className={className}
        icon={icon}
        text={text}
        translate={translate}
      />
    );
  }
  return (
    <ReactstrapButton {...props} color={color}>
      {icon && <i className={`fa fa-${icon}`} />}{' '}
      {text &&
        (translate ? context.intl.formatMessage({ id: text || 'text' }) : text)}
    </ReactstrapButton>
  );
};

Button.contextTypes = {
  intl: intlShape,
};

export default Button;
