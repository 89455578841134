import PropTypes from 'prop-types';
import { parseUrl, stringify } from 'query-string';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import ErrorBoundary from 'components/ErrorBoundary';
import LoadingIndicator from 'components/LoadingIndicator';
import Spinner from 'components/Spinner';
import { makeSelectLocation, makeSelectTypes } from 'containers/App/selectors';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { changeQuery, fetchEntities, setProps } from './actions';
import Layout from './Layout';
import options from './options';
import reducer from './reducer';
import saga from './saga';
import {
  makeSelectTipsterStatisticEntities,
  makeSelectTipsterStatisticEntitiesCount,
  makeSelectTipsterStatisticEntitiesError,
  makeSelectTipsterStatisticEntitiesLoading,
  makeSelectTipsterStatisticEntitiesQuery,
  makeSelectTipsterStatisticRawEntities,
} from './selectors';

class TipsterStatistics extends PureComponent {
  componentDidMount() {
    const { query } = parseUrl(this.props.location.search);
    this.props.onChangeQuery(query, true);
  }

  componentWillUnmount() {
    // reset state
    this.props.setProps();
  }

  render() {
    let { query } = parseUrl(this.props.location.search);
    if (options.pushQuery) {
      query = Object.assign(
        query,
        this.props.query.toJS ? this.props.query.toJS() : this.props.query,
      );
      query = Object.keys(query)
        .filter(key => query[key] !== '' && query[key] !== null)
        .reduce((acc, key) => ({ ...acc, [key]: query[key] }), {});
      query = `?${stringify(query)}`;
    }
    return (
      <ErrorBoundary>
        <Spinner
          {...this.props}
          component={this.props.entities ? Layout : LoadingIndicator}
        >
          {options.pushQuery && window.location.search !== query && (
            <Redirect
              push
              to={{
                search: query,
              }}
            />
          )}
        </Spinner>
      </ErrorBoundary>
    );
  }
}

TipsterStatistics.propTypes = {
  pushQuery: PropTypes.bool,
  location: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  query: PropTypes.object,
  count: PropTypes.number,
  entities: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  showEntities: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setProps: PropTypes.func,
  onChangeQuery: PropTypes.func,
  types: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectTipsterStatisticEntitiesLoading(),
  error: makeSelectTipsterStatisticEntitiesError(),
  entities: makeSelectTipsterStatisticEntities(),
  rawEntities: makeSelectTipsterStatisticRawEntities(),
  query: makeSelectTipsterStatisticEntitiesQuery(),
  count: makeSelectTipsterStatisticEntitiesCount(),
  location: makeSelectLocation(),
  types: makeSelectTypes(),
});

const mapDispatchToProps = dispatch => ({
  setProps: newProps => {
    dispatch(setProps(newProps));
  },
  onChangeQuery: (evt, triggerFetch, syncServer = true) => {
    let queryChange = evt;
    if (evt.target) {
      evt.preventDefault();
      queryChange = { [evt.target.name]: evt.target.value };
    }
    dispatch(changeQuery(queryChange));
    if (triggerFetch) {
      dispatch(fetchEntities(syncServer));
    }
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'TipsterStatistics', reducer });
const withSaga = injectSaga({ key: 'TipsterStatistics', saga });

export default compose(withReducer, withSaga, withConnect)(TipsterStatistics);
