export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const SITES = [
  'external_atg',
  'external_atg_international',
  'external_bet25',
  'external_rikstoto',
  'external_tr_spel_b2b',
  'andelsspel',
  'galopptips',
  'joker_sport',
  'jokersystemet',
  'jokersystemet_no',
  'overodds',
  'spelvarde',
  'travronden',
  'travronden_spel',
  'trais',
  'trhub',
  'trmedia',
];
