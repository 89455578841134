export const TIPSTER_STATISTICS_SET_PROPS =
  'app/containers/Shared/Report/TipsterStatistics/TIPSTER_STATISTICS_SET_PROPS';
export const TIPSTER_STATISTICS_ENTITIES_FETCH =
  'app/containers/Shared/Report/TipsterStatistics/TIPSTER_STATISTICS_ENTITIES_FETCH';
export const TIPSTER_STATISTICS_ENTITIES_FETCH_ERROR =
  'app/containers/Shared/Report/TipsterStatistics/TIPSTER_STATISTICS_ENTITIES_FETCH_ERROR';
export const TIPSTER_STATISTICS_ENTITIES_FETCH_SUCCESS =
  'app/containers/Shared/Report/TipsterStatistics/TIPSTER_STATISTICS_ENTITIES_FETCH_SUCCESS';
export const QUERY_CHANGE =
  'app/containers/Shared/Report/TipsterStatistics/QUERY_CHANGE';
