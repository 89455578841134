import { store } from 'store';

import { makeSelectUser } from './selectors';

const auth = {
  getUser: () => {
    const user = makeSelectUser()(store.getState());
    return user ? user.toJS() : null;
  },

  loggedIn: () => auth.getUser() != null,

  hasRole: (role, site) => {
    const user = auth.getUser() || {};
    const groups = user.groups || {};
    if (groups[site]) {
      return groups[site].includes(role);
    }
    return false;
  },

  inGroup: site => {
    if (!site) {
      return true;
    }

    const user = auth.getUser() || {};
    const groups = user.groups || {};

    if (
      groups[site] &&
      groups[site].some(r =>
        ['admin', 'editor', 'blogger', 'contributor'].includes(r),
      )
    ) {
      return true;
    }
    return false;
  },
};

export default auth;
