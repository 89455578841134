import { format } from 'date-fns';

import { LOCALE_DATE_FORMAT } from 'components/DayPicker/utils';
import { apiUrl } from 'utils/request';

const options = {
  endpoints: {
    list: (site, query) => ({
      url: apiUrl(site, 'reports/tipsters/', {
        start_date: query.start_date,
        end_date: query.end_date,
        track: query.track,
        bet_type: query.bet_type,
      }),
    }),
  },
  // query for search/sort/pager
  query: {
    start_date: `${new Date().getFullYear()}-01-01`,
    end_date: format(new Date(), LOCALE_DATE_FORMAT),
  },
  pushQuery: true,
};
export default options;
