import { fromJS } from 'immutable';

import {
  AUTOTEXT_STATISTICS_ENTITIES_FETCH,
  AUTOTEXT_STATISTICS_ENTITIES_FETCH_ERROR,
  AUTOTEXT_STATISTICS_ENTITIES_FETCH_SUCCESS,
  AUTOTEXT_STATISTICS_SET_PROPS,
  QUERY_CHANGE,
} from './constants';
import { report_calculate } from './helper';
import options from './options';

const initialState = fromJS({
  loading: false,
  error: false,
  query: options.query || {},
  entities: false,
  rawEntities: false,
});

function Reducer(state = initialState, action) {
  switch (action.type) {
    case AUTOTEXT_STATISTICS_SET_PROPS: {
      if (Object.keys(action.newProps || {}).length === 0) {
        return initialState;
      }
      let nextState = state;
      Object.keys(action.newProps).forEach(prop => {
        nextState = nextState.set(prop, action.newProps[prop]);
      });
      return nextState;
    }
    case AUTOTEXT_STATISTICS_ENTITIES_FETCH:
      return state.set('loading', true).set('error', false);
    case AUTOTEXT_STATISTICS_ENTITIES_FETCH_SUCCESS:
      return state
        .set(
          'entities',
          report_calculate([...action.data], state.get('query').toJS()),
        )
        .set('rawEntities', action.data)
        .set('loading', false);
    case AUTOTEXT_STATISTICS_ENTITIES_FETCH_ERROR:
      return state.set('error', action.error).set('loading', false);
    case QUERY_CHANGE: {
      return state.set(
        'query',
        fromJS(
          Object.assign(
            state.get('query') ? state.get('query').toJS() : {},
            action.query.toJS ? action.query.toJS() : action.query,
          ),
        ),
      );
    }
    default:
      return state;
  }
}

export default Reducer;
