export const REPORT_FINANCIAL_RESULTS_SET_PROPS =
  'app/containers/TrAis/Report/FinancialReport/list/REPORT_FINANCIAL_RESULTS_SET_PROPS';
export const REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH =
  'app/containers/TrAis/Report/FinancialReport/list/REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH';
export const REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_ERROR =
  'app/containers/TrAis/Report/FinancialReport/list/REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_ERROR';
export const REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_SUCCESS =
  'app/containers/TrAis/Report/FinancialReport/list/REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_SUCCESS';
export const QUERY_CHANGE =
  'app/containers/TrAis/Report/FinancialReport/list/QUERY_CHANGE';
export const SET_DETAILED_USER =
  'app/containers/TrAis/Report/FinancialReport/list/SET_DETAILED_USER';
