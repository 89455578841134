const report_order = (entities, ordering) => {
  if (ordering) {
    // parse orderings
    const sortOrder = ordering.charAt(0) === '-' ? '-' : '';
    const orderBy = ordering.slice(sortOrder.length);
    // sort
    if (orderBy) {
      return entities.sort((a, b) => {
        const aOrder = a[orderBy] ?? -10000000;
        const bOrder = b[orderBy] ?? -10000000;

        if (sortOrder === '-') {
          return aOrder > bOrder ? -1 : 1;
        } else {
          return aOrder > bOrder ? 1 : -1;
        }
      });
    }
  }
  return entities;
};

export const report_calculate = (entities, query = {}) =>
  report_order(entities, query.ordering);
