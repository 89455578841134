import { format } from 'date-fns';

export const LOCALE_DATE_FORMAT = 'yyyy-MM-dd';
export const LOCALE_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const LOCALE_TIME_ZONE = 'Europe/Stockholm';

// Returns readable date string
export const formatPickerDate = (date, range, showTimePicker, time) => {
  if (date == null) {
    return range ? '-' : undefined;
  }

  // Range selection
  if (range) {
    return [
      date.from ? format(date.from, LOCALE_DATE_FORMAT) : undefined,
      date.to ? format(date.to, LOCALE_DATE_FORMAT) : undefined,
    ].join(' - ');
  }

  // Single selection with time
  if (showTimePicker) {
    return format(combinedTimeDate(date, time), LOCALE_DATE_TIME_FORMAT);
  }

  // Single selection
  return format(date, LOCALE_DATE_FORMAT);
};

export const combinedTimeDate = (date, time) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const combinedDate = new Date(
    year,
    month,
    day,
    time.split(':')[0],
    time.split(':')[1],
  );

  return combinedDate;
};
