import 'whatwg-fetch';

import { stringify } from 'query-string';

import { API_ROOT, REACT_APP_TRMEDIA_API_KEY } from 'containers/App/constants';
import truth from 'containers/Auth/truth';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  const contentType = response.headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }

  if (contentType && contentType.includes('text/xml')) {
    return response.blob();
  }
  return null;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  /*
  if (response.ok && response.status < 400) {
    return response;
  }
  */
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const contentType = response.headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    return response.json().then(json => {
      const error = new Error(response.statusText);
      error.response = response;
      error.jsonMessage = json; // message from server
      throw error;
    });
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

// build request URL
/*
 endpoint: endpoint name
 args: arguments in query url
 version: version of api (default v1)
 */
export function apiUrl(site, endpoint, _args) {
  let url = API_ROOT[site] || false;
  if (!url) {
    return null;
  }
  /*
  if (version !== false) {
    version = version || API_VERSION;
    url = API_ROOT + version + '/';
  }
  */
  url += endpoint || '';

  const args = _args || {};
  const query = {};
  Object.keys(args).map(name => {
    if (args[name] !== '' && args[name] !== null && args[name] !== 'null') {
      query[name] = args[name];
    }
    return name;
  });

  if (Object.keys(query).length > 0) {
    url = `${url}?${stringify(query)}`;
  }
  return url;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(site, url, _options) {
  function makeRequest({ refresh }) {
    const { csrfToken } = truth;
    const options = _options || {};

    options.headers = options.headers || {};
    if (options.headers['Content-Type'] === null) {
      delete options.headers['Content-Type'];
    } else {
      options.headers['Content-Type'] =
        options.headers['Content-Type'] || 'application/json';
    }
    if (options.headers.SITE || site) {
      options.headers.SITE = options.headers.SITE || site;
    }

    options.headers['TRMedia-API-Key'] = REACT_APP_TRMEDIA_API_KEY;
    options.headers['X-CSRFToken'] = csrfToken == null ? '' : csrfToken;
    options.headers['X-JWT-CSRF-Token'] = csrfToken == null ? '' : csrfToken;
    options.credentials = 'include';

    return fetch(url, options).then(response => {
      if (refresh && response.status === 401) {
        return (
          truth
            .refreshDebounced()
            // Try to make the request again regardless of whether the refresh
            // succeeded.
            // 1. The user’s tokens get messed up.
            // 2. That causes 401 for _all_ API requests (that’s how Django
            //    REST Framework works).
            // 3. The refresh fails (the user isn’t logged in anymore).
            // 4. But the bad tokens have been cleared, so the requests should
            //    succeed now.
            .then(
              () => makeRequest({ refresh: false }),
              () => makeRequest({ refresh: false }),
            )
        );
      }
      return response;
    });
  }
  return makeRequest({ refresh: true }).then(checkStatus).then(parseJSON);
}
