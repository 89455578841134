import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatPickerDate } from '../utils';
import styles from './styles.module.scss';

const DayPickerToggler = ({
  toggleDayPicker,
  selectedDate,
  range,
  showTimePicker,
  time,
  buttonRef,
}) => (
  <button
    className={classNames(styles.daypickerToggler, 'border')}
    onClick={toggleDayPicker}
    type="button"
    ref={buttonRef}
  >
    <div className="border-right">
      <span className="fa fa-calendar p-2" />
    </div>
    <span className="px-2">
      {formatPickerDate(selectedDate, range, showTimePicker, time) ?? '-'}
    </span>
  </button>
);

DayPickerToggler.propTypes = {
  toggleDayPicker: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([
    PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null]),
  ]),
  range: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  time: PropTypes.string,
  buttonRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default DayPickerToggler;
