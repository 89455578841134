import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production'],
  maxBreadcrumbs: 30,
  plugins: [new BugsnagPluginReact(React)],
  onError: () => {
    // Log only production errors
    if (process.env.REACT_APP_VERSION == null) {
      return false;
    }
  },
});

export { default } from '@bugsnag/js';
