import PropTypes from 'prop-types';
import * as React from 'react';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Input from 'components/Form/Elements/Input';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { fetchMeets } from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectMeets, makeSelectMeetsLoading } from './selectors';

class Meet extends React.Component {
  componentDidMount() {
    this.props.onFetchMeets(this.props.query);
  }

  render() {
    const {
      dispatch,
      meets,
      loading,
      emptyLabel,
      component: Component = Input,
      valueby,
      onFetchMeets,
      translate,
      ...rest
    } = this.props;

    return (
      <Component {...rest} type="select">
        <option value="">
          {loading
            ? `${this.context.intl.formatMessage({ id: 'Loading' })}...`
            : translate
              ? this.context.intl.formatMessage({ id: emptyLabel })
              : emptyLabel}
        </option>
        {meets &&
          meets.map(meet => (
            <option key={meet.id} value={meet[valueby || 'slug']}>
              {meet.track_name} - {meet.date}
            </option>
          ))}
      </Component>
    );
  }
}

Meet.contextTypes = {
  intl: intlShape,
};

Meet.propTypes = {
  loading: PropTypes.bool,
  meets: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  query: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectMeetsLoading(),
  meets: makeSelectMeets(),
});

const mapDispatchToProps = dispatch => ({
  onFetchMeets: query => {
    dispatch(fetchMeets(query));
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'meet', reducer });
const withSaga = injectSaga({ key: 'meet', saga });

export default compose(withReducer, withSaga, withConnect)(Meet);
