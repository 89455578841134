import { apiUrl } from 'utils/request';

const options = {
  endpoints: {
    types: site => ({
      url: apiUrl(site, 'content/types/'),
      options: {
        headers: { SITE: site },
      },
    }),
  },
};

export default options;
