import { createRef, PureComponent } from 'react';
import { intlShape } from 'react-intl';
import { Table } from 'reactstrap';

import Item from './Item';

class List extends PureComponent {
  listRef = createRef();

  entityMapList = [
    {
      key: 'label',
      label: 'Faktor',
    },
    {
      key: 'count',
      label: 'Antal',
    },
    {
      key: 'percentage',
      label: 'Procent',
    },
  ];

  sorting(evt, ordering) {
    let sortOrder = false;
    if (evt.target.classList.contains('sorting_asc')) {
      sortOrder = '-';
    } else if (
      evt.target.classList.contains('sorting') ||
      evt.target.classList.contains('sorting_desc')
    ) {
      sortOrder = '';
    }

    if (sortOrder !== false) {
      // reset all
      const sorters = this.listRef.current.querySelectorAll([
        '.sorting_asc',
        '.sorting_desc',
      ]);

      sorters.forEach(sorter => {
        sorter.classList.add('sorting');
        sorter.classList.remove(['sorting_asc', 'sorting_desc']);
      });

      this.props.onChangeQuery({ ordering: sortOrder + ordering }, true, false);
    }
  }

  buildHeaders() {
    const ordering = this.props.query ? this.props.query.ordering : '';
    let sortOrder = 'asc';
    if (ordering && ordering.charAt(0) === '-') {
      sortOrder = 'desc';
    }

    let orderBy;
    if (ordering && ordering.charAt(0) !== '-') {
      orderBy = ordering;
    } else if (ordering && ordering.charAt(0) === '-') {
      orderBy = ordering.slice(1);
    }

    return [
      <tr key="th-row-1">
        {this.entityMapList.map(({ key, label }) => (
          <th
            key={`stats-th-${key}`}
            style={{ position: 'sticky', top: 0, left: 0, zIndex: 2 }}
            className={`border-left border-right sorting_hide_icon ${
              orderBy && orderBy === key ? `sorting_${sortOrder}` : 'sorting'
            }`}
            onClick={evt => this.sorting(evt, key)}
          >
            {label}
          </th>
        ))}
      </tr>,
    ];
  }

  render() {
    return (
      <div
        ref={this.listRef}
        className="table-responsive"
        style={{ maxHeight: Math.max(window.innerHeight - 300, 600) }}
      >
        <Table className="table table-responsive-sm table-hover table-outline mb-0 dataTable">
          <thead className="thead-light">{this.buildHeaders()}</thead>
          <tbody>
            {(this.props.entities || []).map(entity => (
              <Item
                key={`stats-${entity.label}`}
                entity={entity}
                {...this.props}
                entityMapList={this.entityMapList}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

List.contextTypes = {
  intl: intlShape,
};

export default List;
