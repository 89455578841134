import { useCallback } from 'react';

const Item = props => {
  const { entity, entityMapList, setDetailedUser } = props;

  const handleDetailedUserClick = useCallback(() => {
    setDetailedUser(entity.published_by);
  }, [entity.published_by, setDetailedUser]);

  return (
    <tr>
      {entityMapList.map(cell => {
        let text = cell.field ? entity[cell.field] : null;

        if (cell.format) {
          text = cell.format(text, props);
        }
        return (
          <td key={`cell_${cell.field}`}>
            {cell.field === 'published_by_name' ? (
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={handleDetailedUserClick}
              >
                {text}
              </button>
            ) : (
              text
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default Item;
