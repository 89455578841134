import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link as RouteLink } from 'react-router-dom';

const Link = ({ text, icon, translate, ...rest }) => (
  <RouteLink {...rest}>
    {icon && <i className={`fa fa-${icon}`} />}{' '}
    {translate ? <FormattedMessage id={text || 'text'} /> : text}
  </RouteLink>
);

Link.propTypes = {
  translate: PropTypes.bool,
};

Link.defaultProps = {
  translate: true,
};

export default Link;
