const sidebarMinimize = () =>
  document.body.classList.toggle('sidebar-minimized');

const brandMinimize = () => document.body.classList.toggle('brand-minimized');

function SidebarMinimizer() {
  return (
    <button
      type="button"
      className="sidebar-minimizer"
      onClick={() => {
        sidebarMinimize();
        brandMinimize();
      }}
    />
  );
}

export default SidebarMinimizer;
