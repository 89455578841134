import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';

import { formatPickerDate } from '../utils';
import styles from './styles.module.scss';

const DayPickerFooter = ({
  selectedDate,
  range,
  showTimePicker,
  timeValue,
  setTimeValue,
  apply,
  clear,
  closePopper,
  required,
  disabled,
}) => {
  const handleSubmit = () => {
    apply();
    closePopper();
  };

  const handleTimeChange = e => {
    const time = e.target.value;
    setTimeValue(time);
  };

  const isRangeIncomplete =
    range &&
    ((selectedDate?.from == null && selectedDate?.to != null) ||
      (selectedDate?.to == null && selectedDate?.from != null));

  return (
    <div className={styles.daypickerFooter}>
      {showTimePicker && (
        <Input
          type="time"
          name="time"
          onChange={handleTimeChange}
          className={classNames(styles.timePicker, 'border', 'rounded')}
          value={timeValue}
          disabled={disabled}
        />
      )}
      <div className="d-flex align-items-center border-top pt-2 p-2">
        <small className="text-muted ml-auto mr-2">
          {formatPickerDate(selectedDate, range, showTimePicker, timeValue)}
        </small>
        <div>
          {!required && (
            <Button
              type="button"
              className="text-dark font-weight-bold picker-btn"
              color="transparent"
              size="sm"
              onClick={clear}
            >
              Rensa
            </Button>
          )}
          <Button
            type="button"
            size="sm"
            color="primary"
            className={styles.pickerBtn}
            onClick={handleSubmit}
            disabled={isRangeIncomplete}
          >
            Välj
          </Button>
        </div>
      </div>
    </div>
  );
};

DayPickerFooter.propTypes = {
  selectedDate: PropTypes.oneOfType([
    PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null]),
  ]),
  range: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  timeValue: PropTypes.string,
  setTimeValue: PropTypes.func,
  apply: PropTypes.func,
  clear: PropTypes.func,
  closePopper: PropTypes.func,
  required: PropTypes.bool,
};

export default DayPickerFooter;
