import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectSite } from 'containers/App/selectors';
import request from 'utils/request';

import { fetchErrorEntities, fetchSuccessEntities } from './actions';
import { AUTOTEXT_STATISTICS_ENTITIES_FETCH } from './constants';
import options from './options';
import {
  makeSelectAutotextStatisticEntitiesQuery,
  makeSelectAutotextStatisticRawEntities,
} from './selectors';

export function* sagaAutotextStatisticFetchEntities(action) {
  const site = yield select(makeSelectSite());
  const query = yield select(makeSelectAutotextStatisticEntitiesQuery());
  const endpoint = options.endpoints.list(site, query);
  const rawData = yield select(makeSelectAutotextStatisticRawEntities());

  if (action.syncServer) {
    try {
      const response = yield call(
        request,
        site,
        endpoint.url,
        endpoint.options,
      );
      yield put(fetchSuccessEntities(response));
    } catch (error) {
      yield put(fetchErrorEntities(error));
    }
  } else {
    yield put(fetchSuccessEntities(rawData || []));
  }
}

export default function* sagaAutotextStatisticWatchList() {
  yield takeLatest(
    AUTOTEXT_STATISTICS_ENTITIES_FETCH,
    sagaAutotextStatisticFetchEntities,
  );
}
