import { createSelector } from 'reselect';

const selectFinancialResults = state => state.get('reportFinancials');

const makeSelectFinancialResultEntitiesQuery = () =>
  createSelector(selectFinancialResults, substate =>
    substate.get('query').toJS(),
  );

const makeSelectFinancialResultEntitiesLoading = () =>
  createSelector(selectFinancialResults, substate => substate.get('loading'));

const makeSelectFinancialResultEntitiesError = () =>
  createSelector(selectFinancialResults, substate => substate.get('error'));

const makeSelectFinancialResultEntities = () =>
  createSelector(selectFinancialResults, substate => substate.get('entities'));

const makeSelectFinancialResultDetailedUser = () =>
  createSelector(selectFinancialResults, substate =>
    substate.get('detailedUser'),
  );

export {
  makeSelectFinancialResultDetailedUser,
  makeSelectFinancialResultEntities,
  makeSelectFinancialResultEntitiesError,
  makeSelectFinancialResultEntitiesLoading,
  makeSelectFinancialResultEntitiesQuery,
  selectFinancialResults,
};
