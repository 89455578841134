import { CardHeader as ReactstrapCardHeader } from 'reactstrap';

export default function CardHeader({ title, actions, collapse, ...rest }) {
  return (
    <ReactstrapCardHeader {...rest}>
      <div
        role="presentation"
        onClick={
          collapse != null
            ? e => {
                e.preventDefault();
                collapse.toggle();
              }
            : undefined
        }
      >
        {title}
      </div>
      {collapse != null || actions?.length > 0 ? (
        <div className="card-actions">
          {collapse != null && (
            <button
              type="button"
              onClick={e => {
                e.preventDefault();
                collapse.toggle();
              }}
              className="btn btn-minimize"
            >
              <i
                className={collapse.state ? 'icon-arrow-up' : 'icon-arrow-down'}
              />
            </button>
          )}
          {actions?.map(action => action)}
        </div>
      ) : null}
    </ReactstrapCardHeader>
  );
}
