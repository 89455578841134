import { createSelector } from 'reselect';

const selectAuth = state => state.get('auth');

const makeSelectLoading = () =>
  createSelector(selectAuth, subState => subState.get('loading'));

const makeSelectError = () =>
  createSelector(selectAuth, subState => subState.get('error'));

const makeSelectUser = () =>
  createSelector(selectAuth, subState => subState.get('user'));

export { makeSelectError, makeSelectLoading, makeSelectUser, selectAuth };
