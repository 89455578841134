import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectSite } from 'containers/App/selectors';
import request from 'utils/request';

import { fetchSuccessMeets } from './actions';
import { MEET_MEETS_FETCH } from './constants';
import options from './options';

export function* sagaMeetFetchMeets(action) {
  const site = yield select(makeSelectSite());

  try {
    const endpoint = options.endpoints.list(site, action.query);
    const response = yield call(request, site, endpoint.url, endpoint.options);
    yield put(fetchSuccessMeets({ results: response }));
  } catch (error) {
    console.error(error);
  }
}

export default function* sagaMeetWatch() {
  yield takeLatest(MEET_MEETS_FETCH, sagaMeetFetchMeets);
}
