import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectSite } from 'containers/App/selectors';

const SiteHandler = WrappedComponent => props => {
  // We don´t want to pass dispatch to wrapped component
  const { dispatch, ...rest } = props;

  return <WrappedComponent {...rest} />;
};

const mapStateToProps = createStructuredSelector({
  site: makeSelectSite(),
});

const withSite = compose(connect(mapStateToProps), SiteHandler);

export default withSite;
