import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { intlShape } from 'react-intl';
import { Form, FormGroup } from 'reactstrap';

import Button from 'components/Button';
import DayPicker, { isRangeSet, makeDate } from 'components/DayPicker';
import { LOCALE_DATE_FORMAT } from 'components/DayPicker/utils';
import Tipster from 'containers/Elements/Tipster/Loadable';
import Track from 'containers/Elements/Track/Loadable';
import Type from 'containers/Elements/Type/Loadable';

class Filter extends PureComponent {
  constructor(props) {
    super(props);
    const { ordering, ...filter } = this.props.query;
    this.filter = { ...filter };
  }

  handleStartDate(dates) {
    if (isRangeSet(dates)) {
      this.filter.start_date = format(dates.from, LOCALE_DATE_FORMAT);
      this.filter.end_date = format(dates.to, LOCALE_DATE_FORMAT);
    } else {
      this.filter.start_date = null;
      this.filter.end_date = null;
    }
  }

  onSubmit = evt => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const syncServer = [
      this.filter.start_date !== this.props.query.start_date,
      this.filter.end_date !== this.props.query.end_date,
      this.filter.track !== this.props.query.track,
      this.filter.bet_type !== this.props.query.bet_type,
    ].some(Boolean);
    this.props.onChangeQuery(
      Object.assign({}, this.props.query, this.filter),
      true,
      syncServer,
    );
  };

  render() {
    return (
      <Form inline onSubmit={this.onSubmit}>
        <FormGroup className="mb-sm-2 mr-sm-3 mb-sm-0">
          <DayPicker
            range
            required
            initStart={makeDate(this.props.query.start_date)}
            initEnd={makeDate(this.props.query.end_date)}
            onApply={date => this.handleStartDate(date)}
          />
        </FormGroup>
        <FormGroup className="mb-sm-2 mr-sm-3 mb-sm-0">
          <Track
            name="track"
            emptyLabel="Bana"
            value={this.props.query.track}
            valueby="id"
            onChange={evt => {
              this.filter.track = evt.target.value;
            }}
          />
        </FormGroup>
        <FormGroup className="mb-sm-2 mr-sm-3 mb-sm-0">
          <Type
            map="bet_types"
            name="bet_type"
            empty_label="Speltyp"
            value={this.props.query.bet_type}
            exclude={['V5']}
            onChange={value => {
              this.filter.bet_type = value;
            }}
          />
        </FormGroup>
        <FormGroup className="mb-sm-2 mr-sm-3 mb-sm-0">
          <Tipster
            multiple
            name="tipsters"
            exclude_from_toplists
            empty_label="Tipsters"
            selected={this.props.query.tipsters}
            onChange={values => {
              this.filter.tipsters = values.map(val => val.id || val);
            }}
          />
        </FormGroup>
        <FormGroup className="mb-sm-2 mb-sm-0">
          <Button text="Search" icon="search" />
        </FormGroup>
      </Form>
    );
  }
}

Filter.contextTypes = {
  intl: intlShape,
};

Filter.propTypes = {
  rawEntities: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  query: PropTypes.object,
  onChangeQuery: PropTypes.func,
};

export default Filter;
