import {
  QUERY_CHANGE,
  REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH,
  REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_ERROR,
  REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_SUCCESS,
  REPORT_FINANCIAL_RESULTS_SET_PROPS,
  SET_DETAILED_USER,
} from './constants';

export function setProps(newProps) {
  return {
    type: REPORT_FINANCIAL_RESULTS_SET_PROPS,
    newProps,
  };
}

export function fetchEntities(syncServer, userId) {
  return {
    type: REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH,
    syncServer,
    userId,
  };
}

export function fetchErrorEntities(error) {
  return {
    type: REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_ERROR,
    error,
  };
}

export function fetchSuccessEntities(site, data) {
  return {
    type: REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_SUCCESS,
    site,
    data,
  };
}

export function changeQuery(query) {
  return {
    type: QUERY_CHANGE,
    query,
  };
}

export function setDetailedUser(user) {
  return {
    type: SET_DETAILED_USER,
    user,
  };
}
