import { apiUrl } from 'utils/request';

const options = {
  endpoints: {
    list: (site, query) => ({
      url: apiUrl(site, 'reports/automated_text_statistics', {
        meet_id: query.meet,
      }),
    }),
  },

  pushQuery: true,
};
export default options;
