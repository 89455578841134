import { createSelector } from 'reselect';

const selectMeetList = state => state.get('meet');

const makeSelectMeetsLoading = () =>
  createSelector(selectMeetList, substate => substate?.get('loading'));

const makeSelectMeets = () =>
  createSelector(selectMeetList, substate => substate?.get('meets'));

export { makeSelectMeets, makeSelectMeetsLoading, selectMeetList };
