import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { intlShape } from 'react-intl';
import { Form, FormGroup } from 'reactstrap';

import Button from 'components/Button';
import Meet from 'containers/Elements/AutotextMeet';

class Filter extends PureComponent {
  constructor(props) {
    super(props);
    const { ordering, ...filter } = this.props.query;
    this.filter = { ...filter };
  }

  onSubmit = evt => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    this.props.onChangeQuery(
      Object.assign({}, this.props.query, this.filter),
      true,
      this.filter.meet !== this.props.query.meet,
    );
  };

  render() {
    return (
      <Form inline onSubmit={this.onSubmit}>
        <FormGroup className="mb-sm-2 mr-sm-3 mb-sm-0">
          <Meet
            name="meet"
            emptyLabel="Meet"
            value={this.props.query.meet}
            valueby="id"
            translate
            onChange={evt => {
              this.filter.meet = evt.target.value;
            }}
          />
        </FormGroup>
        <FormGroup className="mb-sm-2 mb-sm-0">
          <Button text="Search" icon="search" />
        </FormGroup>
      </Form>
    );
  }
}

Filter.contextTypes = {
  intl: intlShape,
};

Filter.propTypes = {
  query: PropTypes.object,
  onChangeQuery: PropTypes.func,
};

export default Filter;
