import PropTypes from 'prop-types';
import { parseUrl, stringify } from 'query-string';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import ErrorBoundary from 'components/ErrorBoundary';
import LoadingIndicator from 'components/LoadingIndicator';
import Spinner from 'components/Spinner';
import { makeSelectLocation, makeSelectTypes } from 'containers/App/selectors';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { changeQuery, fetchEntities, setProps } from './actions';
import Layout from './Layout';
import options from './options';
import reducer from './reducer';
import saga from './saga';
import {
  makeSelectAutotextStatisticEntities,
  makeSelectAutotextStatisticEntitiesCount,
  makeSelectAutotextStatisticEntitiesError,
  makeSelectAutotextStatisticEntitiesLoading,
  makeSelectAutotextStatisticEntitiesQuery,
} from './selectors';

class AutotextStatistics extends PureComponent {
  componentDidMount() {
    const { query } = parseUrl(this.props.location.search);
    this.props.onChangeQuery(query, true);
  }

  componentWillUnmount() {
    // reset state
    this.props.setProps();
  }

  render() {
    const { entities } = this.props;

    let { query } = parseUrl(this.props.location.search);
    if (options.pushQuery) {
      query = Object.assign(
        query,
        this.props.query.toJS ? this.props.query.toJS() : this.props.query,
      );
      query = Object.keys(query)
        .filter(key => query[key] !== '' && query[key] !== null)
        .reduce((acc, key) => ({ ...acc, [key]: query[key] }), {});

      query = `?${stringify(query)}`;
    }

    return (
      <ErrorBoundary>
        <Spinner
          {...this.props}
          component={entities ? Layout : LoadingIndicator}
        >
          {options.pushQuery && window.location.search !== query && (
            <Redirect
              push
              to={{
                search: query,
              }}
            />
          )}
        </Spinner>
      </ErrorBoundary>
    );
  }
}

AutotextStatistics.propTypes = {
  pushQuery: PropTypes.bool,
  location: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  query: PropTypes.object,
  count: PropTypes.number,
  entities: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  showEntities: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setProps: PropTypes.func,
  onChangeQuery: PropTypes.func,
  types: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectAutotextStatisticEntitiesLoading(),
  error: makeSelectAutotextStatisticEntitiesError(),
  entities: makeSelectAutotextStatisticEntities(),
  query: makeSelectAutotextStatisticEntitiesQuery(),
  count: makeSelectAutotextStatisticEntitiesCount(),
  location: makeSelectLocation(),
  types: makeSelectTypes(),
});

const mapDispatchToProps = dispatch => ({
  setProps: newProps => {
    dispatch(setProps(newProps));
  },
  onChangeQuery: (evt, triggerFetch, syncServer = true) => {
    let queryChange = evt;
    if (evt.target) {
      evt.preventDefault();
      queryChange = { [evt.target.name]: evt.target.value };
    }
    dispatch(changeQuery(queryChange));
    if (triggerFetch) {
      dispatch(fetchEntities(syncServer));
    }
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'AutotextStatistics', reducer });
const withSaga = injectSaga({ key: 'AutotextStatistics', saga });

export default compose(withReducer, withSaga, withConnect)(AutotextStatistics);
