import { delay } from 'redux-saga';

const REFRESH_TIMEOUT = 100; // ms

const CSRF_STORAGE_KEY = 'csrfToken';
const JWT_STORAGE_KEY = 'jwtToken';

class AuthClient {
  constructor() {
    this.refreshPromise = undefined;
    this.csrfToken = undefined;
    this.user = undefined;
    this.jwtToken = undefined;
  }

  get csrfToken() {
    return localStorage.getItem(CSRF_STORAGE_KEY);
  }

  set csrfToken(newValue) {
    if (newValue == null) {
      localStorage.removeItem(CSRF_STORAGE_KEY);
    } else {
      localStorage.setItem(CSRF_STORAGE_KEY, newValue);
    }
  }

  get jwtToken() {
    return localStorage.getItem(JWT_STORAGE_KEY);
  }

  set jwtToken(newValue) {
    if (newValue == null) {
      localStorage.removeItem(JWT_STORAGE_KEY);
    } else {
      localStorage.setItem(JWT_STORAGE_KEY, newValue);
    }
  }

  refresh() {
    return fetch('/oauth/validate', {
      credentials: 'include',
    })
      .then(response => Promise.all([response.status, response.json()]))
      .then(([responseStatus, responseData]) => {
        if (responseStatus < 200 || responseStatus >= 300) {
          const error = new Error(responseData.message || 'Unknown error');
          error.status = responseStatus;
          if (responseStatus === 401 || responseStatus === 403) {
            this.csrfToken = null;
            this.jwtToken = null;
            this.user = null;
          }

          throw error;
        }
        return responseData;
      })
      .then(responseData => {
        this.csrfToken = responseData.csrf_token;
        this.user = responseData.user;
        this.jwtToken = responseData.access_token;

        return responseData;
      });
  }

  // On many pages we make many API requests at the same time. Debouncing makes
  // it possible to only make one refresh request instead of one per API
  // request.
  refreshDebounced() {
    if (this.refreshPromise != null) {
      return this.refreshPromise;
    }

    this.refreshPromise = delay(REFRESH_TIMEOUT)
      .then(() => this.refresh())
      .then(
        response => {
          this.refreshPromise = undefined;
          return response;
        },
        error => {
          this.refreshPromise = undefined;
          throw error;
        },
      );

    return this.refreshPromise;
  }
}

const truthClient = new AuthClient();

export default truthClient;
