import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectSite } from 'containers/App/selectors';
import request from 'utils/request';

import { fetchErrorEntities, fetchSuccessEntities } from './actions';
import { TIPSTER_STATISTICS_ENTITIES_FETCH } from './constants';
import options from './options';
import {
  makeSelectTipsterStatisticEntitiesQuery,
  makeSelectTipsterStatisticRawEntities,
} from './selectors';

export function* sagaTipsterStatisticFetchEntities(action) {
  const site = yield select(makeSelectSite());
  const query = yield select(makeSelectTipsterStatisticEntitiesQuery());
  const endpoint = options.endpoints.list(site, query);
  const rawData = yield select(makeSelectTipsterStatisticRawEntities());

  if (action.syncServer) {
    try {
      const response = yield call(
        request,
        site,
        endpoint.url,
        endpoint.options,
      );
      yield put(fetchSuccessEntities(response));
    } catch (error) {
      yield put(fetchErrorEntities(error));
    }
  } else {
    yield put(fetchSuccessEntities(rawData));
  }
}

export default function* sagaTipsterStatisticWatchList() {
  yield takeLatest(
    TIPSTER_STATISTICS_ENTITIES_FETCH,
    sagaTipsterStatisticFetchEntities,
  );
}
