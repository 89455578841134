import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import Bugsnag from 'utils/bugsnag';

import { authRequestSuccess } from './actions';
import truth from './truth';
import { getLoginUrl } from './utils';

const SECOND = 1000;
const MINUTE = 60 * 1000;
const MAX_REFRESH_DELAY = 10 * MINUTE;

export function* refreshAuthSaga() {
  let attempts = 0;
  while (true) {
    attempts += 1;
    const timeout = Math.min(MAX_REFRESH_DELAY, 1.8 ** attempts * SECOND);

    try {
      const response = yield call([truth, 'refreshDebounced']);
      const { user } = response;
      Bugsnag.setUser(
        user.id,
        user.email,
        `${user.first_name} ${user.last_name}`,
      );
      yield put(authRequestSuccess(user));
      // The user is logged in and the token is refreshed. All done.
      break;
    } catch (error) {
      if (error.status === 401 || error.status === 403) {
        // The saved token is invalid or expired. Reset everything.
        yield call(() => {
          window.location.href = getLoginUrl();
        });
        break;
      }

      const time =
        timeout < MINUTE
          ? `${(timeout / SECOND).toFixed(2)} seconds`
          : `${(timeout / MINUTE).toFixed(2)} minutes`;
      console.warn(
        `Token refresh on startup failed. Retrying in ${time}`,
        error,
      );
    }

    yield call(delay, timeout);
  }
}
