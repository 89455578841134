import auth from 'containers/Auth/auth';

export const navExternal = {
  external_atg: [
    {
      title: true,
      name: 'ATG',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Tävlingsdagar',
      url: '/external_atg/meet',
      icon: 'icon-list',
    },
    {
      name: 'Omgångar',
      url: '/external_atg/round',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/external_atg/authors',
      icon: 'icon-people',
    },
    {
      name: 'Rapporter',
      url: '/external_atg/reports',
      icon: 'icon-graph',
      children: [
        {
          name: 'Autotext-statistik ',
          url: '/external_atg/reports/autotext-statistics',
        },
      ],
    },
  ],
  external_atg_international: [
    {
      title: true,
      name: 'ATG: Internationellt',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Tävlingsdagar',
      url: '/external_atg_international/meet',
      icon: 'icon-list',
    },
    {
      name: 'Omgångar',
      url: '/external_atg_international/round',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/external_atg_international/authors',
      icon: 'icon-people',
    },
  ],
  external_bet25: [
    {
      title: true,
      name: 'Bet 25',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Tävlingsdagar',
      url: '/external_bet25/meet',
      icon: 'icon-list',
    },
    {
      name: 'Omgångar',
      url: '/external_bet25/round',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/external_bet25/authors',
      icon: 'icon-people',
    },
  ],
  external_rikstoto: [
    {
      title: true,
      name: 'Rikstoto',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Tävlingsdagar',
      url: '/external_rikstoto/meet',
      icon: 'icon-list',
    },
    {
      name: 'Omgångar',
      url: '/external_rikstoto/round',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/external_rikstoto/authors',
      icon: 'icon-people',
    },
  ],
  external_tr_spel_b2b: [
    {
      title: true,
      name: 'TR Spel B2B',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Tävlingsdagar',
      url: '/external_tr_spel_b2b/meet',
      icon: 'icon-list',
    },
    {
      name: 'Omgångar',
      url: '/external_tr_spel_b2b/round',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/external_tr_spel_b2b/authors',
      icon: 'icon-people',
    },
    {
      name: 'Rapporter',
      url: '/external_tr_spel_b2b/reports',
      icon: 'icon-graph',
      children: [
        {
          name: 'Autotext-statistik ',
          url: '/external_tr_spel_b2b/reports/autotext-statistics',
        },
      ],
    },
  ],
};

export const getNavExternal = () => {
  const user = auth.getUser() || {};
  let items = [];

  Object.keys(user.groups || {})
    .sort((a, b) => a.localeCompare(b))
    .forEach(site => {
      if (navExternal[site] && auth.inGroup(site)) {
        items = [...items, ...navExternal[site]];
      }
    });

  return items.length > 1
    ? [
        {
          title: true,
          name: 'Externa API:er',
          wrapper: {
            element: '',
            attributes: {},
          },
          class: '',
        },
        {
          name: 'Övervakning',
          url: '/overview',
          icon: 'icon-eye',
        },
        ...items,
      ]
    : [];
};
