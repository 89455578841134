import { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { intlShape } from 'react-intl';
import { Col, Row } from 'reactstrap';

import Card from 'components/Card';

import Filter from './Filter';
import List from './List';

class Layout extends PureComponent {
  render() {
    return (
      <div>
        <Helmet
          title={this.context.intl.formatMessage({
            id: 'Reports > Autotext Statistics',
          })}
        />
        <div className="animated fadeIn">
          <Card
            title={{
              text: 'Reports > Autotext Statistics',
              withBorder: true,
              icon: 'icon-graph',
            }}
          >
            <Row className="pt-2">
              <Col>
                <div className="float-right form-group">
                  <Filter {...this.props} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <List {...this.props} />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  }
}

Layout.contextTypes = {
  intl: intlShape,
};

export default Layout;
