import './global-styles';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';

import { translationMessages } from './i18n';
import { history, store } from './store';

console.info(`TR Content ${process.env.REACT_APP_VERSION}. © TR Media.`);

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <ConnectedRouter history={history}>
        <App history={history} />
      </ConnectedRouter>
    </LanguageProvider>
  </Provider>,
  document.querySelector('#app'),
);
