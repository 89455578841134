import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import DayPicker, { isRangeSet, makeDate } from 'components/DayPicker';
import { LOCALE_DATE_FORMAT } from 'components/DayPicker/utils';
import { SITES } from 'utils/constants';
import withSite from 'utils/withSite';

const Filter = props => {
  const { query, onChangeQuery } = props;
  const filter = { ...query };

  return (
    <DayPicker
      range
      closeOnClear
      required
      initStart={makeDate(query.start_date)}
      initEnd={makeDate(query.end_date)}
      onApply={date => {
        if (isRangeSet(date)) {
          filter.start_date = format(new Date(date.from), LOCALE_DATE_FORMAT);
          filter.end_date = format(new Date(date.to), LOCALE_DATE_FORMAT);

          const syncServer =
            filter.start_date !== query.start_date ||
            filter.end_date !== query.end_date;

          onChangeQuery({ ...query, ...filter }, true, syncServer);
        } else {
          filter.start_date = null;
          filter.end_date = null;
        }
      }}
    />
  );
};

Filter.contextTypes = {
  intl: intlShape,
};

Filter.propTypes = {
  entities: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  query: PropTypes.object,
  onChangeQuery: PropTypes.func,
  site: PropTypes.oneOf(SITES).isRequired,
};

export default withSite(Filter);
