import { fromJS } from 'immutable';

import {
  QUERY_CHANGE,
  REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH,
  REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_ERROR,
  REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_SUCCESS,
  REPORT_FINANCIAL_RESULTS_SET_PROPS,
  SET_DETAILED_USER,
} from './constants';
import options from './options';

const initialState = fromJS({
  loading: false,
  error: false,
  query: options.query || {},
  entities: false,
  detailedUser: null,
});

function Reducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_FINANCIAL_RESULTS_SET_PROPS: {
      if (!action.newProps) {
        return initialState;
      }
      let nextState = state;
      Object.keys(action.newProps).forEach(prop => {
        nextState = nextState.set(prop, action.newProps[prop]);
      });
      return nextState;
    }
    case REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH:
      return state.set('loading', true).set('error', false);
    case REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_SUCCESS:
      return state.set('entities', action.data).set('loading', false);
    case REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH_ERROR:
      return state.set('error', action.error).set('loading', false);
    case QUERY_CHANGE: {
      return state.set(
        'query',
        fromJS(
          Object.assign(
            state.get('query') ? state.get('query').toJS() : {},
            action.query.toJS ? action.query.toJS() : action.query,
          ),
        ),
      );
    }
    case SET_DETAILED_USER:
      return state.set('detailedUser', action.user);
    default:
      return state;
  }
}

export default Reducer;
