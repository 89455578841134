import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

export default function CardTitle({ text, withBorder, icon, ...rest }) {
  return (
    <Fragment>
      <h3 className="card-title clearfix mb-0" {...rest}>
        <span>
          <i className={icon || 'fa fa-align-justify'} />{' '}
          <FormattedMessage id={text} />
        </span>
      </h3>
      {withBorder && <hr />}
    </Fragment>
  );
}
