import 'react-day-picker/dist/style.css';

import { AvInput } from 'availity-reactstrap-validation';
import classNames from 'classnames';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale/sv';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DayPicker as ReactDayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';

import DayPickerFooter from './DayPickerFooter';
import DayPickerToggler from './DayPickerToggler';
import styles from './styles.module.scss';
import { combinedTimeDate } from './utils';

const FROM_YEAR = new Date().getFullYear() - 10;
const TO_YEAR = new Date().getFullYear() + 1;
const CAPTION_LAYOUT = 'dropdown-buttons';
const POPPER_PLACEMENT = 'bottom-start';
const DEFAULT_TIME = '00:00';

// Check if date range is valid
export const isRangeSet = date => {
  if (date?.from != null && date?.to != null) {
    return true;
  }

  return false;
};

// Returns a Date object from a date string and a time string
// If time is provided, it will be combined with the date
export const makeDate = (date, time) => {
  if (date == null || date === '') {
    return;
  }

  if (time == null) {
    return new Date(date);
  }

  const [hours, minutes] = time.split(':').map(Number);

  const combinedDate = new Date(date);
  combinedDate.setHours(hours);
  combinedDate.setMinutes(minutes);

  return combinedDate;
};

const getTime = (date, range) => {
  if (date == null || range) {
    return '00:00';
  }

  return format(date?.from || date, 'HH:mm');
};

const getDefaultMonth = (selectedDate, range) => {
  // If it's a range selection, use the first date as default month
  if (selectedDate?.from != null) {
    return selectedDate.from;
  }

  // If it's a single selection, use the selected date as default month
  if (!range && selectedDate != null) {
    return selectedDate;
  }

  return new Date();
};

/* 
  - This component uses https://react-day-picker.js.org
  - Expects a date object
  - Set range to true if you want to select a range of dates
  - Set autoApply to true if you want to apply the selected date(s) automatically
*/
const DayPicker = ({
  initStart,
  initEnd,
  range,
  onApply,
  autoApply,
  disabled,
  showFooter = true,
  showTimePicker,
  name,
  value,
  validate,
  required = false,
  closeOnClear,
}) => {
  const popperRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: POPPER_PLACEMENT,
  });
  const buttonRef = useRef(null);

  // Initialize date(s)
  const [selectedDate, setSelectedDate] = useState(
    range
      ? {
          from: initStart != null ? initStart : undefined,
          to: initEnd != null ? initEnd : undefined,
        }
      : initStart,
  );

  const [timeValue, setTimeValue] = useState(getTime(selectedDate, range));

  // Handle whenever the user hovers over a date and the range is incomplete (only one from-date is selected)
  const [hoveredDate, setHoveredDate] = useState(null);

  const handleDayMouseEnter = day => {
    if (range && !selectedDate?.to && selectedDate?.from != null) {
      setHoveredDate(day);
    }
  };

  // If the range is incomplete and the user clicks outside the popper, empty the dates
  useEffect(() => {
    if (
      range &&
      ((selectedDate?.to == null && selectedDate?.from != null) ||
        (selectedDate?.from == null && selectedDate?.to != null)) &&
      !isPopperOpen
    ) {
      setSelectedDate({ from: undefined, to: undefined });
    }
  }, [isPopperOpen, range, selectedDate]);

  const togglePopper = () => setIsPopperOpen(!isPopperOpen);

  // Handle whenever the user selects a date
  const handleSelect = selection => {
    // Prevent the user from selecting the same date
    // causing the date to be deselected (single-selection)
    if (!range && selectedDate != null && selection == null) {
      return;
    }

    // Reset the two selected dates if the user presses another date
    // then select the newly pressed date
    if (range && isRangeSet(selectedDate) && selection != null) {
      if (selectedDate.from !== selection.from) {
        setSelectedDate({ from: selection.from, to: undefined });
      }

      if (selectedDate.to !== selection.to) {
        setSelectedDate({ from: selection.to, to: undefined });
      }

      return;
    }

    setSelectedDate(range ? selection : combinedTimeDate(selection, timeValue));

    if (autoApply) {
      handleApply(selection);
      togglePopper();
    }
  };

  // Handle whenever the user clicks the apply button
  const handleApply = useCallback(
    date => {
      onApply(
        range
          ? {
              from: date?.from ? makeDate(date.from) : undefined,
              to: date?.to ? makeDate(date.to) : undefined,
            }
          : makeDate(date, timeValue),
      );
    },
    [onApply, range, timeValue],
  );

  // Handle whenever the user clicks the clear button
  const clearDate = () => {
    setSelectedDate(range ? { from: undefined, to: undefined } : undefined);
    setTimeValue(DEFAULT_TIME);
    handleApply();

    if (closeOnClear) {
      togglePopper();
    }
  };

  return (
    <div>
      <div ref={popperRef}>
        <DayPickerToggler
          toggleDayPicker={togglePopper}
          selectedDate={selectedDate}
          range={range}
          showTimePicker={showTimePicker}
          time={timeValue}
          buttonRef={buttonRef}
        />
        {validate && (
          <AvInput
            name={name}
            className="d-none"
            value={value}
            validate={validate}
          />
        )}
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: () => {
              togglePopper();
              setHoveredDate(null);
            },
            fallbackFocus: buttonRef.current,
          }}
        >
          <div
            className={classNames(
              styles.daypickerWrapper,
              {
                [styles.daypickerWrapperRange]: range,
              },
              'shadow-lg',
              'border',
              'mt-2',
              'bg-white',
            )}
            tabIndex={-1}
            style={popper.styles.popper}
            ref={setPopperElement}
          >
            <ReactDayPicker
              // Picker specific options
              locale={sv}
              fromYear={FROM_YEAR}
              toYear={TO_YEAR}
              captionLayout={CAPTION_LAYOUT}
              showOutsideDays={!range}
              mode={range ? 'range' : 'single'}
              numberOfMonths={range ? 2 : 1}
              defaultMonth={getDefaultMonth(selectedDate, range)}
              // State and event handlers
              disabled={disabled}
              onSelect={handleSelect}
              selected={selectedDate}
              modifiers={
                range
                  ? {
                      start: selectedDate?.from,
                      end: hoveredDate || selectedDate?.to,
                      selected: hoveredDate &&
                        selectedDate?.from && {
                          from: selectedDate?.from,
                          to: hoveredDate,
                        },
                    }
                  : null
              }
              onDayClick={handleSelect}
              onDayMouseEnter={handleDayMouseEnter}
              // Custom components
              className={classNames(styles.daypicker, {
                [styles.daypickerRange]: range,
                [styles.daypickerSingleSelection]: !range,
              })}
            />
            {showFooter && (
              <DayPickerFooter
                selectedDate={selectedDate}
                range={range}
                showTimePicker={showTimePicker}
                timeValue={timeValue}
                setTimeValue={setTimeValue}
                apply={() => handleApply(selectedDate)}
                clear={clearDate}
                closePopper={togglePopper}
                required={required}
                disabled={disabled}
              />
            )}
          </div>
        </FocusTrap>
      )}
    </div>
  );
};

DayPicker.propTypes = {
  initStart: PropTypes.object, // date object
  initEnd: PropTypes.object, // date object
  range: PropTypes.bool,
  onApply: PropTypes.func,
  autoApply: PropTypes.bool,
  showFooter: PropTypes.bool.isRequired,
  showTimePicker: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  validate: PropTypes.object,
  required: PropTypes.bool.isRequired,
  closeOnClear: PropTypes.bool,
  disabled: PropTypes.bool,
};

DayPicker.defaultProps = {
  showFooter: true,
  required: false,
};

export default DayPicker;
