import { createSelector } from 'reselect';

const selectAutotextStatistics = state => state.get('AutotextStatistics');

const makeSelectAutotextStatisticEntitiesQuery = () =>
  createSelector(selectAutotextStatistics, substate =>
    substate.get('query').toJS(),
  );

const makeSelectAutotextStatisticEntitiesCount = () =>
  createSelector(selectAutotextStatistics, substate => substate.get('count'));

const makeSelectAutotextStatisticEntitiesLoading = () =>
  createSelector(selectAutotextStatistics, substate => substate.get('loading'));

const makeSelectAutotextStatisticEntitiesError = () =>
  createSelector(selectAutotextStatistics, substate => substate.get('error'));

const makeSelectAutotextStatisticEntities = () =>
  createSelector(selectAutotextStatistics, substate =>
    substate.get('entities'),
  );

const makeSelectAutotextStatisticRawEntities = () =>
  createSelector(selectAutotextStatistics, substate =>
    substate.get('rawEntities'),
  );

export {
  makeSelectAutotextStatisticEntities,
  makeSelectAutotextStatisticEntitiesCount,
  makeSelectAutotextStatisticEntitiesError,
  makeSelectAutotextStatisticEntitiesLoading,
  makeSelectAutotextStatisticEntitiesQuery,
  makeSelectAutotextStatisticRawEntities,
  selectAutotextStatistics,
};
