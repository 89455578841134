import isString from 'lodash/isString';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';

import LoadingIndicator from 'components/LoadingIndicator';

const Spinner = props => {
  const notElementErrors = new Set(['detail']);
  const success = props.success ?? undefined;
  let error;
  // let shouldRetry = false;
  const ChildComponent = props.loading ? LoadingIndicator : props.component;
  if (props.error) {
    /* if (props.error.response && props.error.response.status > 400) {
      shouldRetry = true;
    } */
    error = props.error.message;
    if (props.error.jsonMessage) {
      const errors = props.error.jsonMessage;
      if (!isString(errors)) {
        error = [];
        Object.keys(errors).forEach(index => {
          if (typeof errors[index] !== 'string') {
            Object.keys(errors[index]).forEach(i => {
              if (!notElementErrors.has(index)) {
                if (
                  Object.keys(errors[index][i]) < 1 &&
                  typeof errors[index][i] === 'object'
                ) {
                  error.push(
                    <div key={`${index}.${i}`}>
                      {`- ${index}: ${errors[index][i][0]}`}
                    </div>,
                  );
                } else if (typeof errors[index][i] === 'string') {
                  error.push(
                    <div key={`${index}.${i}`}>
                      {`- ${index}: ${errors[index][i]}`}
                    </div>,
                  );
                } else {
                  Object.keys(errors[index][i]).forEach(key => {
                    error.push(
                      <div key={`${index}.${key}`}>
                        {`- ${index}: ${errors[index][i][key]}`}
                      </div>,
                    );
                  });
                }
              } else {
                error.push(<div key={`${index}.${i}`}>{errors[index][i]}</div>);
              }
            });
          } else {
            error.push(<div key={index}>{errors[index]}</div>);
          }
        });
      } else {
        error = errors;
        /*
        if (notElementErrors.indexOf(index) === -1) {
          error.push(<div key={index}>- {index} : {errors[index]}</div>)
        } else {
          error.push(<div key={index}>{errors[index]}</div>)
        }
        */
      }
    }
  }
  return (
    <div>
      {success && (
        <Alert color="success">
          {success === true ? (
            <FormattedMessage id="This content is saved" />
          ) : (
            success
          )}
        </Alert>
      )}

      {error && (
        <Alert color="danger">
          {error}

          {/* shouldRetry &&
        <div><a className='text-blue' onClick={() => window.location.href = window.location.href}>Click here to retry <i
          className='fa fa-refresh'></i></a>
        </div>
        */}
        </Alert>
      )}
      {ChildComponent && (
        <Fragment>
          {props.children}
          <ChildComponent {...props} />
        </Fragment>
      )}

      {!ChildComponent && <Fragment>{props.children}</Fragment>}
    </div>
  );
};

export default Spinner;
