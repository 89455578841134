import { createSelector } from 'reselect';

const selectTipsterStatistics = state => state.get('TipsterStatistics');

const makeSelectTipsterStatisticEntitiesQuery = () =>
  createSelector(selectTipsterStatistics, substate =>
    substate.get('query').toJS(),
  );

const makeSelectTipsterStatisticEntitiesCount = () =>
  createSelector(selectTipsterStatistics, substate => substate.get('count'));

const makeSelectTipsterStatisticEntitiesLoading = () =>
  createSelector(selectTipsterStatistics, substate => substate.get('loading'));

const makeSelectTipsterStatisticEntitiesError = () =>
  createSelector(selectTipsterStatistics, substate => substate.get('error'));

const makeSelectTipsterStatisticEntities = () =>
  createSelector(selectTipsterStatistics, substate => substate.get('entities'));

const makeSelectTipsterStatisticRawEntities = () =>
  createSelector(selectTipsterStatistics, substate =>
    substate.get('rawEntities'),
  );

export {
  makeSelectTipsterStatisticEntities,
  makeSelectTipsterStatisticEntitiesCount,
  makeSelectTipsterStatisticEntitiesError,
  makeSelectTipsterStatisticEntitiesLoading,
  makeSelectTipsterStatisticEntitiesQuery,
  makeSelectTipsterStatisticRawEntities,
  selectTipsterStatistics,
};
