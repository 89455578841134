import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { DefaultLayout } from 'components/Layout';
import LoadingIndicator from 'components/LoadingIndicator';
import auth from 'containers/Auth/auth';
import { makeSelectUser } from 'containers/Auth/selectors';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { fetchTypes, setSite } from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectSite, makeSelectTypes } from './selectors';

class AppRoute extends PureComponent {
  componentDidMount() {
    const { site } = this.props;

    this.props.setSite(site);

    if (site) {
      this.props.fetchTypes();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.site !== prevProps.site) {
      this.props.setSite(this.props.site);
    }

    if (
      (this.props.site && this.props.site !== prevProps.site) ||
      (this.props.site && !this.props.types)
    ) {
      this.props.fetchTypes();
    }
  }

  render() {
    const {
      component: Component,
      layout,
      permissions,
      site,
      reduxSite,
      types,
      user,
      ...rest
    } = this.props;

    const Layout = layout || DefaultLayout;

    if (user == null) {
      // Wait for refreshAuthSaga to complete
      return null;
    }

    return (
      <Route
        {...rest}
        render={matchProps => {
          const checkAccess = validAccess(site, permissions);

          if (checkAccess.isAccess) {
            const content =
              site && (!types || site !== reduxSite) ? (
                <LoadingIndicator />
              ) : (
                <Component {...matchProps} />
              );

            return <Layout>{content}</Layout>;
          }

          const { redirectPath } = checkAccess;

          if (redirectPath) {
            return <Redirect to={redirectPath} />;
          }

          return null;
        }}
      />
    );
  }
}

const validAccess = (site, permissions) => {
  if (site) {
    return auth.inGroup(site)
      ? {
          isAccess: true,
        }
      : {
          isAccess: false,
          redirectPath: auth.loggedIn() ? '/sites' : null,
        };
  }

  if (!permissions) {
    return {
      isAccess: true,
    };
  }

  if (permissions === 'logged in' && auth.loggedIn()) {
    return {
      isAccess: true,
    };
  }

  if (permissions === 'not logged in' && !auth.loggedIn()) {
    return {
      isAccess: true,
    };
  }

  // requires not logged in but user is already logged in.
  if (permissions === 'not logged in' && auth.loggedIn()) {
    return {
      isAccess: false,
      redirectPath: '/sites',
    };
  }

  return {
    isAccess: false,
  };
};

AppRoute.propTypes = {
  fetchTypes: PropTypes.func.isRequired,
  setSite: PropTypes.func.isRequired,
  reduxSite: PropTypes.string,
};

const mapStateToProps = () =>
  createStructuredSelector({
    reduxSite: makeSelectSite(),
    types: makeSelectTypes(),
    user: makeSelectUser(),
  });

const mapDispatchToProps = (dispatch, props) => ({
  fetchTypes: () => {
    dispatch(fetchTypes(props.site));
  },
  setSite: site => {
    dispatch(setSite(site));
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'global', reducer });
const withSaga = injectSaga({ key: 'global', saga });

export default compose(withReducer, withSaga, withConnect)(AppRoute);
