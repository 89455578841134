import { Component } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from 'reactstrap';

import auth from 'containers/Auth/auth';
import { getLoginUrl, getLogoutUrl } from 'containers/Auth/utils';

class NavRight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));
  };

  render() {
    if (auth.loggedIn()) {
      const user = auth.getUser() || {};
      const name = [user.first_name, user.last_name].filter(Boolean).join(' ');
      return (
        <Nav className="ml-auto mr-1 mr-md-4" navbar>
          <NavItem>
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle className="nav-link dropdown-toggle">
                <span className="d-md-down-none">{name || 'Meny'}</span>{' '}
                {this.props.loading && <i className="fa fa-spin fa-spinner" />}
              </DropdownToggle>
              <DropdownMenu
                right
                className={this.state.dropdownOpen ? 'show' : ''}
              >
                <DropdownItem tag="a" href={getLogoutUrl()}>
                  <i className="fa fa-unlock" /> Logga ut
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavItem>
        </Nav>
      );
    }
    return (
      <Nav className="ml-auto" navbar>
        <NavItem>
          <a href={getLoginUrl()}>Login</a>
        </NavItem>
      </Nav>
    );
  }
}

export default NavRight;
