import { fromJS } from 'immutable';

import { SET_SITE, TYPES_FETCH, TYPES_FETCH_SUCCESS } from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  types: {},
  site: null,
});

function globalReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES_FETCH:
      return state;
    case TYPES_FETCH_SUCCESS:
      return state.setIn(['types', action.site], action.types);
    case SET_SITE:
      return state.set('site', action.site);
    default:
      return state;
  }
}

export default globalReducer;
