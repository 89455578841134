import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FormattedNumber, intlShape } from 'react-intl';
import { Table } from 'reactstrap';

import { SITES } from 'utils/constants';
import withSite from 'utils/withSite';

import Item from './Item';
import options from './options';

const List = (props, context) => {
  const entityMapList = options.entityMapList;

  const { totalCompensation, totalRacesCompleted } = useMemo(() => {
    if (!props.entities) {
      return { totalCompensation: 0, totalRacesCompleted: 0 };
    }
    return props.entities.reduce(
      (acc, entity) => ({
        totalCompensation: acc.totalCompensation + entity.total_compensation,
        totalRacesCompleted: acc.totalRacesCompleted + entity.details.length,
      }),
      { totalCompensation: 0, totalRacesCompleted: 0 },
    );
  }, [props.entities]);

  const buildHeaders = () =>
    Object.keys(entityMapList).map(key => {
      const cell = entityMapList[key];
      const label = cell.label
        ? context.intl.formatMessage({ id: cell.label })
        : '';

      return <th key={key}>{label}</th>;
    });

  const buildFooters = () => {
    if (!props.entities) {
      return null;
    }

    return (
      <>
        <td>Totalt</td>
        <td>{totalRacesCompleted}</td>
        <td>
          <FormattedNumber
            value={totalCompensation / 100}
            minimumFractionDigits={2}
          />
        </td>
      </>
    );
  };

  const buildItems = () => {
    if (!props.entities) {
      return null;
    }
    return props.entities.map((entity, index) => (
      <Item
        key={index}
        entity={entity}
        {...props}
        entityMapList={entityMapList}
      />
    ));
  };

  return (
    <div className="table-responsive">
      <Table className="table table-responsive-sm table-hover table-outline mb-0 dataTable">
        <thead className="thead-light">
          <tr>{buildHeaders()}</tr>
        </thead>
        <tbody>{buildItems()}</tbody>
        <tfoot className="bg-light">
          <tr>{buildFooters()}</tr>
        </tfoot>
      </Table>
    </div>
  );
};

List.propTypes = {
  site: PropTypes.oneOf(SITES).isRequired,
};

List.contextTypes = {
  intl: intlShape,
};

export default withSite(List);
