import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NumericInput from 'react-numeric-input';
import { FormGroup, Input as InputComponent, Label } from 'reactstrap';

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
    });
  }

  handleChange(evt) {
    this.setState({
      value: evt.target.value,
    });
    if (this.props.onChange) {
      this.props.onChange(evt);
    }
  }

  handleNumericInputChange(valueAsNumber, valueAsString, evt) {
    if (this.props.onChange) {
      this.props.onChange(valueAsNumber, valueAsString, evt);
    }
  }

  render() {
    const props = Object.assign({}, this.props);
    props.className = props.className || '';
    props.className += ' form-control';
    let child;
    switch (props.type) {
      case 'number':
        props.onChange = this.handleNumericInputChange.bind(this);
        child = <NumericInput {...props} />;
        break;
      default:
        props.value = this.state.value;
        props.onChange = this.handleChange.bind(this);
        child = <InputComponent {...props} />;
        break;
    }

    if (props.label) {
      return (
        <FormGroup>
          <Label>
            <FormattedMessage id={props.label} />
          </Label>
          {child}
        </FormGroup>
      );
    }

    return child;
  }
}
