import { NavbarBrand, NavbarToggler } from 'reactstrap';

import NavRight from './NavRight';

const sidebarToggle = e => {
  e.preventDefault();
  document.body.classList.toggle('sidebar-hidden');
};

const mobileSidebarToggle = e => {
  e.preventDefault();
  document.body.classList.toggle('sidebar-mobile-show');
};

function Header() {
  return (
    <header className="app-header navbar">
      <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
        <span className="navbar-toggler-icon" />
      </NavbarToggler>
      <NavbarBrand href="/" className="border-0" />
      <NavbarToggler className="d-md-down-none mr-auto" onClick={sidebarToggle}>
        <span className="navbar-toggler-icon" />
      </NavbarToggler>
      <NavRight />
    </header>
  );
}

export default Header;
