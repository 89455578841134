import { createSelector } from 'reselect';

const selectRoute = state => state.get('route');

const selectGlobal = state => state.get('global');

const selectSite = createSelector(selectGlobal, substate =>
  substate ? substate.get('site') : null,
);

const makeSelectLoading = () =>
  createSelector(selectGlobal, globalState => globalState.get('loading'));

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.get('error'));

const makeSelectLocation = () =>
  createSelector(selectRoute, routeState => routeState.get('location').toJS());

/*
type is an array like
 ['content_types']
 ['widgets', 'key_matches', 'types']
id is an object to search like
 {id: "match_analysis"}
 {key: "round_best"}
*/
const makeSelectTypes = () =>
  createSelector([selectSite, selectGlobal], (site, substate) =>
    site && substate ? substate.getIn(['types', site]) : false,
  );

const makeSelectFeatures = () =>
  createSelector([selectSite, selectGlobal], (site, substate) =>
    site && substate
      ? Object.keys(substate.getIn(['types', site]).widgets)
      : false,
  );

const makeSelectRankingCategories = () =>
  createSelector([selectSite, selectGlobal], (site, substate) =>
    site && substate
      ? substate.getIn(['types', site]).widgets?.ranking?.categories
      : false,
  );

const makeSelectSite = () => selectSite;

export {
  makeSelectError,
  makeSelectFeatures,
  makeSelectLoading,
  makeSelectLocation,
  makeSelectRankingCategories,
  makeSelectSite,
  makeSelectTypes,
};
