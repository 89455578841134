import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const AnimatedIndicator = () => (
  <i className={`fa fa-spinner fa-pulse fa-3x ${styles.icon}`} />
);

const LoadingIndicator = ({ overlay }) => (
  <div
    className={classNames(styles.base, {
      [styles.overlay]: overlay,
      [styles.static]: !overlay,
    })}
  >
    <AnimatedIndicator />
  </div>
);

LoadingIndicator.propTypes = {
  overlay: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  overlay: false,
};

export default LoadingIndicator;
