import { createRef, PureComponent } from 'react';
import { intlShape } from 'react-intl';
import { Table } from 'reactstrap';

import Item from './Item';

class List extends PureComponent {
  listRef = createRef();

  sorting(evt, ordering) {
    let sortOrder = false;
    if (evt.target.classList.contains('sorting_asc')) {
      sortOrder = '-';
    } else if (
      evt.target.classList.contains('sorting') ||
      evt.target.classList.contains('sorting_desc')
    ) {
      sortOrder = '';
    }

    if (sortOrder !== false) {
      // reset all
      const sorters = this.listRef.current.querySelectorAll([
        '.sorting_asc',
        '.sorting_desc',
      ]);

      sorters.forEach(sorter => {
        sorter.classList.add('sorting');
        sorter.classList.remove(['sorting_asc', 'sorting_desc']);
      });

      this.props.onChangeQuery({ ordering: sortOrder + ordering }, true, false);
    }
  }

  buildHeaders() {
    const ordering = this.props.query ? this.props.query.ordering : '';
    let sortOrder = 'asc';
    if (ordering && ordering.charAt(0) === '-') {
      sortOrder = 'desc';
    }

    let orderBy;
    if (ordering && ordering.charAt(0) !== '-') {
      orderBy = ordering;
    } else if (ordering && ordering.charAt(0) === '-') {
      orderBy = ordering.slice(1);
    }

    return [
      <tr key="th-row-1">
        <th
          rowSpan={2}
          style={{ position: 'sticky', top: 0, left: 0, zIndex: 2 }}
          className={`border-left border-right sorting_hide_icon ${
            orderBy && orderBy === 'tipster'
              ? `sorting_${sortOrder}`
              : 'sorting'
          }`}
          onClick={evt => this.sorting(evt, 'tipster')}
        >
          Tipster
        </th>
        {this.props.types.tipster_stats.map(({ label, types }, index) => (
          <th
            key={index}
            style={{ position: 'sticky', top: 0, whiteSpace: 'pre' }}
            className="border-left"
            colSpan={types.length}
          >
            {label}
          </th>
        ))}
      </tr>,
      <tr key="th-row-2">
        {this.props.types.tipster_stats.reduce((columns, map) => {
          columns.push(
            ...map.types.map(({ key, label, hint }, index) => {
              let className = 'sorting';
              if (orderBy && orderBy === key) {
                className = `sorting_${sortOrder}`;
              }
              return (
                <th
                  key={key}
                  title={hint}
                  style={{ position: 'sticky', top: 48, whiteSpace: 'pre' }}
                  className={`text-right sorting_hide_icon ${className} ${
                    index < 1 ? 'border-left' : ''
                  }`}
                  onClick={evt => this.sorting(evt, key)}
                >
                  {label}
                </th>
              );
            }),
          );
          return columns;
        }, [])}
      </tr>,
    ];
  }

  render() {
    return (
      <div
        ref={this.listRef}
        className="table-responsive"
        style={{ maxHeight: Math.max(window.innerHeight - 300, 600) }}
      >
        <Table className="table table-responsive-sm table-hover table-outline mb-0 dataTable">
          <thead className="thead-light">{this.buildHeaders()}</thead>
          <tbody>
            {(this.props.entities || []).map(entity => (
              <Item
                key={`stats-${entity.tipster.id}`}
                entity={entity}
                {...this.props}
                entityMapList={this.props.types.tipster_stats}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

List.contextTypes = {
  intl: intlShape,
};

export default List;
