import { format } from 'date-fns';
import { FormattedNumber } from 'react-intl';
import { Table } from 'reactstrap';

import { LOCALE_DATE_TIME_FORMAT } from 'components/DayPicker/utils';
import Link from 'components/Link';

const UserDetailedReport = ({ userDetails }) => (
  <Table className="table table-responsive-sm table-hover table-outline mb-0 dataTable">
    <thead className="thead-light">
      <tr>
        <th>Lopp</th>
        <th>Publicerad</th>
        <th>Inrapporterad efter (dagar)</th>
        <th>Belopp (SEK)</th>
      </tr>
    </thead>

    <tbody>
      {userDetails.details.map((detail, index) => (
        <tr key={index}>
          <td>
            <Link
              key={index}
              to={`/trais/meet/${detail.meet_id}/race_${detail.race_id}`}
              translate={false}
              text={`${detail.track_name}-${detail.race_number}`}
            />
          </td>

          <td>{format(new Date(detail.published), LOCALE_DATE_TIME_FORMAT)}</td>

          <td>{detail.days_difference || 'Samma dag'}</td>

          <td>
            <FormattedNumber
              value={detail.compensation / 100}
              minimumFractionDigits={2}
            />
          </td>
        </tr>
      ))}
    </tbody>

    <tfoot className="bg-light">
      <tr>
        <td>Totalt</td>

        <td colSpan={2}>{userDetails.count}</td>

        <td>
          <FormattedNumber
            value={userDetails.total_compensation / 100}
            minimumFractionDigits={2}
          />
        </td>
      </tr>
    </tfoot>
  </Table>
);

export default UserDetailedReport;
