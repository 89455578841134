import {
  AUTOTEXT_STATISTICS_ENTITIES_FETCH,
  AUTOTEXT_STATISTICS_ENTITIES_FETCH_ERROR,
  AUTOTEXT_STATISTICS_ENTITIES_FETCH_SUCCESS,
  AUTOTEXT_STATISTICS_SET_PROPS,
  QUERY_CHANGE,
} from './constants';

export function setProps(newProps) {
  return {
    type: AUTOTEXT_STATISTICS_SET_PROPS,
    newProps,
  };
}

export function fetchEntities(syncServer) {
  return {
    type: AUTOTEXT_STATISTICS_ENTITIES_FETCH,
    syncServer,
  };
}

export function fetchErrorEntities(error) {
  return {
    type: AUTOTEXT_STATISTICS_ENTITIES_FETCH_ERROR,
    error,
  };
}

export function fetchSuccessEntities(data) {
  return {
    type: AUTOTEXT_STATISTICS_ENTITIES_FETCH_SUCCESS,
    data,
  };
}

export function changeQuery(query) {
  return {
    type: QUERY_CHANGE,
    query,
  };
}
