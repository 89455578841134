const Item = ({ entity, entityMapList }) => (
  <tr>
    {entityMapList.map(({ key }, index) => {
      let label = entity[key] ?? null;

      if (key === 'percentage') {
        label += '%';
      }

      return (
        <td
          className={`${index > 0 ? 'text-right' : 'border-left'}`}
          key={`stats-td-${key}-${index}`}
        >
          {label}
        </td>
      );
    })}
  </tr>
);

export default Item;
