import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectSite } from 'containers/App/selectors';
import request from 'utils/request';

import { fetchErrorEntities, fetchSuccessEntities } from './actions';
import { REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH } from './constants';
import options from './options';
import { makeSelectFinancialResultEntitiesQuery } from './selectors';

export function* sagaReportSystemResultFetchEntities(action) {
  const site = yield select(makeSelectSite());
  const query = yield select(makeSelectFinancialResultEntitiesQuery());

  if (action.userId) {
    query.userId = action.userId;
  }

  const endpoint = options.endpoints.list(site, query);

  try {
    const response = yield call(request, site, endpoint.url, endpoint.options);
    yield put(fetchSuccessEntities(site, response));
  } catch (error) {
    yield put(fetchErrorEntities(error));
  }
}

export default function* sagaReportSystemResultWatchList() {
  yield takeLatest(
    REPORT_FINANCIAL_RESULTS_ENTITIES_FETCH,
    sagaReportSystemResultFetchEntities,
  );
}
