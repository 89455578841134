function Footer() {
  return (
    <footer className="app-footer">
      <span className="ml-auto">
        Version {process.env.REACT_APP_VERSION}. © TR Media
      </span>
    </footer>
  );
}

export default Footer;
