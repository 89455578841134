import { MEET_MEETS_FETCH, MEET_MEETS_FETCH_SUCCESS } from './constants';

export function fetchMeets(query = {}) {
  return {
    type: MEET_MEETS_FETCH,
    query,
  };
}

export function fetchSuccessMeets(data) {
  return {
    type: MEET_MEETS_FETCH_SUCCESS,
    ...data,
  };
}
