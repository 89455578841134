import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import UserDetailedReport from './UserDetailedReport';

const UserDetailsModal = ({
  entities,
  detailedUser,
  setDetailedUser,
  query,
}) => {
  if (!detailedUser) {
    return null;
  }

  const userDetails = entities.find(
    entity => entity.published_by === detailedUser,
  );

  if (!userDetails) {
    return null;
  }

  return (
    <Modal isOpen size="lg modal-lg">
      <ModalHeader toggle={() => setDetailedUser(null)}>
        {userDetails.published_by_name} {query.start_date} - {query.end_date}
      </ModalHeader>
      <ModalBody>
        <UserDetailedReport userDetails={userDetails} />
      </ModalBody>
    </Modal>
  );
};

export default UserDetailsModal;
