import { fromJS } from 'immutable';

import { MEET_MEETS_FETCH, MEET_MEETS_FETCH_SUCCESS } from './constants';

const initialState = fromJS({
  loading: false,
  meets: false,
});

function Reducer(state = initialState, action) {
  switch (action.type) {
    case MEET_MEETS_FETCH:
      return state.set('loading', true);
    case MEET_MEETS_FETCH_SUCCESS:
      return state.set('meets', action.results).set('loading', false);
    default:
      return state;
  }
}

export default Reducer;
